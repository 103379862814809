import React from "react";

const Hero = () => {
  return (
    <section className="flex-grow flex items-center justify-center bg-gray-50 py-20">
      <div className="text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-blue-600 mb-4">
          MooresLab.AI
        </h1>
        <p className="text-xl text-gray-700 mb-8">
          Agentic AI for Silicon Engineering
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="bg-blue-600 text-white px-6 py-3 rounded-md"
            onClick={() =>
              window.open("https://calendly.com/mooreslab", "_blank")
            }
          >
            Contact Us
          </button>

          <button
            className="bg-blue-600 text-white px-6 py-3 rounded-md"
            onClick={() =>
              window.open(
                "https://www.youtube.com/watch?v=w6p53_D7vMA",
                "_blank"
              )
            }
          >
            View Demo
          </button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
